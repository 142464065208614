import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import FeedStyled from "../components/styles/FeedStyles"
import FeedBox from "../components/feedBox"
import myInitObject from "../ultis/variable"
import Half from "../components/half/index"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { blogCollection, blogSingleton } = data.swapi
  const [selectedCategory, setSelectedCategory] = useState("")
  const [postList, setPostList] = useState([])

  let listCategory = []

  useEffect(() => {
    setPostList(blogCollection)
    if (typeof window !== `undefined`) {
      if (window.location.hash) {
        const tmp = window.location.hash.replace(/(%20)/g, " ")
        setCategory(tmp.substr(1))
      }
    }
  }, [])

  blogCollection.map(category => {
    category.feedCategory.map(item => {
      if (!listCategory.includes(item.replace(/(^[\s]+|[\s]+$)/g, ""))) {
        listCategory.push(item.replace(/(^[\s]+|[\s]+$)/g, ""))
      }
    })
  })

  function setCategory(category) {
    setSelectedCategory(category)
    let listPostTmp = blogCollection.map(item => {
      let tmp = item.feedCategory.map(el => el.replace(/(^[\s]+|[\s]+$)/g, ""))
      if (tmp.includes(category)) {
        return item
      }
    })
    let listPost = listPostTmp.filter(function(el) {
      return el != null
    })
    setPostList(listPost)
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={blogSingleton.meta_title}
        description={blogSingleton.meta_description}
        img={blogSingleton.meta_img ? blogSingleton.meta_img.path : ""}
        lang="en"
      />
      <section className="blogPage">
        <FeedStyled>
          <div className="container container__title">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: blogSingleton.heading }}
              ></h2>
            </TitleStyled>
          </div>
          <div className="container container__category">
            <p className="blogPage__category">{blogSingleton.categotyText}</p>
            {listCategory.length
              ? listCategory.map(category => (
                  <p
                    key={uuid()}
                    className={`blogPage__category__item ${
                      category === selectedCategory
                        ? "blogPage__category__item--active"
                        : ""
                    }`}
                    onClick={() => setCategory(category)}
                  >
                    {category}
                  </p>
                ))
              : null}
          </div>
          <div className="container container__feed blog__feed--3">
            {postList
              ? postList.map(item => (
                  <div key={uuid()} className="feed__item">
                    <FeedBox
                      img={myInitObject.cockpitUrl + item.feedImage.path}
                      title={item.feedTitle}
                      descirption={item.feedDescription}
                      link={"/en/blog/" + item.slug}
                      // linkText={item.feedTextButton}
                      date={item.date}
                      author={item.author.name}
                      titleSize="22"
                    />
                  </div>
                ))
              : null}
          </div>
        </FeedStyled>
      </section>
      <Half key={uuid()} half={blogSingleton.half} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query blogCollectionEN {
    swapi {
      blogCollection(lang: "en") {
        feedCategory
        feedDescription
        feedTextButton
        slug
        feedImage {
          path
        }
        feedTitle
        date
        author {
          name
        }
      }
      blogSingleton(lang: "en") {
        categotyText
        heading
        meta_description
        meta_img {
          path
        }
        meta_title
        half
      }
    }
  }
`
